<template>
  <!-- 吹蜡烛的加载动画 -->
  <van-overlay :show="true">
    <div class="wrapper">
      <div class="body">
        <div class="wrapper">
          <img src="/loading.gif" class="img">
        </div>
        <div class="expand">
          <van-progress :percentage="percentage" :show-pivot="false"/>
          <p class="bottomp">{{text}}，请稍后...</p>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "LoadingAnimate",
  props: {
    text: String,
  },
  data() {
    return {
      percentage: 0, // 进度
    }
  },
  mounted(){
    this.percentage=0
    setInterval(() => {
      if(this.percentage<90) this.percentage+=0.1
    }, 100);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.bottomp {
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  margin: 1rem 0 calc(50% - 100px);
}
.expand{
  width: 80%;
  margin: -1rem auto 0;
}
// 遮罩层
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.img{
  min-height: 260px;
  width: 100vw;
}
</style>